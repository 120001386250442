import { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import Header from "../../hoc/header";
import Footer from "../../hoc/footer";
import Plug from "../../common/Plugs/Plug";
import Button from "../../common/Button/Button";
import ModalExam from "../../common/Modal/ModalExam";

import { general as data } from "./data";

import Car from "../../../assets/img/city/car.svg";
import City from "../../../assets/img/city/city.svg";
import Clouds from "../../../assets/img/city/clouds.svg";
import BirdsTwo from "../../../assets/img/city/birds-2.svg";
import BirdsThree from "../../../assets/img/city/birds.svg";

import "../../../assets/style/main/app.scss";
import { setLogo } from "../../helpers/logo";

const StartedLearn = ({
  onGetExamQuestions,
  onCurrentTheme,
  token,
  questions,
  currentTheme,
  totalProgress,
}) => {
  const history = useHistory();

  const [animation, setAnimation] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [read] = useState(sessionStorage.getItem("read"));

  useEffect(() => {
    setAnimation(true);
  });

  useEffect(() => {
    if (questions?.length > 0) {
      history.push("/test");
      document.body.classList.remove("open");
    }
    // eslint-disable-next-line
  }, [questions]);

  const setExam = () => {
    onCurrentTheme(token);

    if (read) {
      onGetExamQuestions(token);
      sessionStorage.setItem("general", JSON.stringify({ type: "exam" }));
      document.body.classList.remove("open");
    } else {
      setModalInfo(true);
      document.body.classList.add("open");
    }
  };

  return (
    <div className="main">
      {modalInfo && (
        <ModalExam
          setModalInfo={setModalInfo}
          rules={data}
          token={token}
          onGetExamQuestions={onGetExamQuestions}
          currentTheme={currentTheme}
          questions={questions}
        />
      )}
      <div className="main__header">
        <Header />
      </div>
      <NavLink to="/started_learn" className="main__logo">
        <img
          src={setLogo()}
          alt="Pdr Автошкола в смартфоні"
          width="1"
          height="1"
        />
      </NavLink>
      {!token ? (
        <div className="learn__plug">
          <Plug plugText="Увійдіть, щоб почати тестування!" />
        </div>
      ) : (
        <div className={`start ${animation ? "animation" : ""}`}>
          <div className="container start__container">
            <div className="start__btns">
              <NavLink
                exact
                to="/learn"
                replace
                className="start__btn btn btn--yellow btn--rounded-md"
              >
                {totalProgress.total > 0 ? "Продовжити!" : "Розпочати!"}
              </NavLink>
              <Button
                bg="red"
                btnText="Іспит"
                rounded="md"
                otherClass="start__btn"
                onClick={setExam}
              />
            </div>
            <img
              src={Car}
              className="start__car"
              alt="car photo"
              width="1"
              height="1"
            />
            <div className="start__cities">
              <img
                src={City}
                className="start__city"
                alt="city photo"
                width="1"
                height="1"
              />
              <img
                src={City}
                className="start__city"
                alt="city photo"
                width="1"
                height="1"
              />
              <img
                src={City}
                className="start__city"
                alt="city photo"
                width="1"
                height="1"
              />
              <img
                src={City}
                className="start__city"
                alt="city photo"
                width="1"
                height="1"
              />
              <img
                src={City}
                className="start__city"
                alt="city photo"
                width="1"
                height="1"
              />
            </div>
            <img
              src={Clouds}
              className="start__clouds"
              alt="clouds photo"
              width="1"
              height="1"
            />
            <img
              src={BirdsTwo}
              className="start__birds-two"
              alt="birds photo"
              width="1"
              height="1"
            />
            <img
              src={BirdsThree}
              className="start__birds-three"
              alt="birds photo"
              width="1"
              height="1"
            />

            <p className="start__text">
              Готуйтесь до іспиту та вивчайте правила дорожнього руху будь-де і
              будь-коли за допомогою нашого онлайн тренажера. Всі тестові
              питання офіційно затверджені ГСЦ МВС
            </p>
          </div>
        </div>
      )}
      <div className="main__footer">
        <Footer />
      </div>
    </div>
  );
};

export default StartedLearn;
