import React from "react";
import MainHOC from "./routerHOC";
import Popup from "./hoc/popup";
import MetaTags from "./components/MetaTags/MetaTags";

const App = (props) => {
  return (
    <>
      <MetaTags />
      <Popup />
      <MainHOC {...props} />
    </>
  );
};
export default App;
