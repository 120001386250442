import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../hoc/header";
import FinishIcon from "../../images/FinishIcon";
import Footer from "../../hoc/footer";

import "../../../assets/style/main/app.scss";
import { setLogo } from "../../helpers/logo";

const Finish = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setAnimation(true);
  });

  return (
    <div className="main">
      <div className="main__header">
        <Header />
      </div>
      <NavLink to="/started_learn" className="main__logo">
        <img
          src={setLogo()}
          alt="Pdr Автошкола в смартфоні"
          width="1"
          height="1"
        />
      </NavLink>

      <div className={`finish ${animation ? "animation" : ""}`}>
        <div className="container finish__container">
          <div className="finish__circle">
            <FinishIcon position="finish__prise" />
          </div>

          <h2 className="finish__title">Вітаємо!</h2>
          <p className="finish__text">Ви успішно пройшли всі теми!</p>
          <div className="finish__btns">
            {/* <NavLink to="/started_learn" className="btn btn--yellow">Повернутись до проходження тем</NavLink> */}
            <NavLink to="/started_learn" className="btn btn--transparent">
              Повернутись до проходження тем
            </NavLink>
          </div>
        </div>
      </div>

      <div className="main__footer">
        <Footer />
      </div>
    </div>
  );
};

export default Finish;
