import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import ModalPay from "../../hoc/modalPay";

import { images } from "../../helpers/importImg";
import ModalInfo from "../../common/Modal/ModalInfo";
import { setWhiteLogo } from "../../helpers/logo";

const Footer = ({ user, onLogout, token, setLogin, setUser }) => {
  const history = useHistory();

  // ---------------- SET LOCAL STATE ------------------------
  const [modalPay, setModalPay] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);

  // ---------------- SET PREMIUM MODAL ------------------------
  const openModalPay = () => {
    if (token) {
      setModalPay(true);
      document.body.classList.add("open");
    } else {
      setModalInfo(true);
      document.body.classList.add("open");
    }
  };

  // -------------- LOGOUT ----------------
  const logoutUser = () => {
    onLogout(token);
    setLogin(null);
    setUser({});
    sessionStorage.removeItem("token");
    history.push("/");
  };

  return (
    <>
      {modalPay && <ModalPay setModalPay={setModalPay} />}
      {modalInfo && <ModalInfo setModalInfo={setModalInfo} />}

      <footer className="footer-learn">
        <nav className="container footer-learn__container">
          <NavLink to="/">
            <img
              src={setWhiteLogo()}
              className="footer-learn__logo"
              alt="Pdr logo"
              width="1"
              height="1"
            />
          </NavLink>
          <ul className="footer-learn__list">
            <li>
              <NavLink to="/" className="footer-learn__link">
                Головна
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" className="footer-learn__link">
                Про нас
              </NavLink>
            </li>
            {/* <li><NavLink to="/cabinet" className="footer-learn__link">Кабінет</NavLink></li> */}
            {!token ? (
              <li>
                <NavLink to="/login" className="footer-learn__link">
                  Увійти/Реєстрація
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {!token ? (
              !user.premium_check ? (
                <li>
                  <button className="footer-learn__link" onClick={openModalPay}>
                    Преміум
                  </button>
                </li>
              ) : (
                ""
              )
            ) : (
              <li>
                <button className="footer-learn__link" onClick={logoutUser}>
                  Вийти
                </button>
              </li>
            )}
            <NavLink
              className="footer-learn__link"
              to="/agreement"
              target="_blank"
            >
              Угода користувача
            </NavLink>
          </ul>
        </nav>
        <div className="footer-learn__pay container">
          <img
            src={`${images["mastercard.svg"]}`}
            alt="mastercard"
            width="1"
            height="1"
          />
          <img src={`${images["visa.svg"]}`} alt="visa" width="1" height="1" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
