import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ModalPay from "../../hoc/modalPay";
import { images } from "../../helpers/importImg";
import { setWhiteLogo } from "../../helpers/logo";

const Sidebar = ({
  token,
  user,
  onSetThemes,
  onSetCurrentTheme,
  onSetQuestions,
  onGetUser,
  onLogout,
  setLogin,
  setRegister,
  setRecover,
}) => {
  // ------------- GET HISTORY -------------------
  const history = useHistory(null);
  // -------------- GET TIME --------------------
  const currentTime = JSON.parse(sessionStorage.getItem("timer"));
  const general = JSON.parse(sessionStorage.getItem("general"));
  // ------------ SET LOCAL STATE -----------------
  const [active, setActive] = React.useState(false);
  const [modalPay, setModalPay] = useState(false);

  const types = ["/reset_password", "/register/finish", "/"];
  const typesBack = [
    "/register",
    "/register/finish",
    "/recover",
    "/reset_password",
  ];

  useEffect(() => {
    setLogin(sessionStorage.getItem("token"));
    // eslint-disable-next-line
  }, []);

  // -------------- LOGOUT ----------------
  const logoutUser = () => {
    onLogout(token);
    setLogin(null);
    sessionStorage.removeItem("token");
    history.push("/");
    resetTest();
  };

  useEffect(() => {
    if (token && !types.includes(history?.location.pathname)) {
      onGetUser(token);
    }
  }, [token]);

  // ------------- RESET TEST -------------
  const resetTest = () => {
    onSetThemes(null);
    onSetCurrentTheme(null);
    onSetQuestions(null);
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    sessionStorage.removeItem("general");
  };

  // let location = useLocation()
  const [counter, setCounter] = useState(20 * 60);
  const [counterText, setCounterText] = useState(null);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(function () {
        if (history?.location?.pathname !== "/test") {
          return () => clearInterval(timer);
        }
        if (general?.type === "all_thema") {
          return () => clearInterval(timer);
        }
        let seconds = counter % 60;
        let minuts = (counter / 60) % 60;
        let strTimer = `${
          minuts < 10 ? `0${Math.trunc(minuts)}` : Math.trunc(minuts)
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
        setCounterText(strTimer);
        setCounter(counter - 1); // Уменьшаем таймер
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  // -------------------- CHECK TIMER -----------------
  useEffect(() => {
    if (currentTime) {
      setCounter(currentTime);
    }
  }, []);

  const openModalPay = () => {
    setModalPay(true);
  };

  const setPage = () => {
    if (general?.type === "exam") {
      history.push("/started_learn");
    } else {
      history.push("/learn");
    }
  };

  const setBack = () => {
    setRegister(null);
    setRecover(null);
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("group");
    sessionStorage.removeItem("categories");
    history.push("/login");
  };

  return (
    <>
      {modalPay && <ModalPay setModalPay={setModalPay} />}
      <div className="burger__wrapper">
        <button
          aria-label="Відкрити меню"
          title="Відкрити меню"
          className={`burger burger-text ${active ? "active" : ""}`}
          onClick={() => setActive(!active)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        {history?.location?.pathname === "/test" &&
          (general?.type === "theme" || general?.type === "retest" ? (
            ""
          ) : (
            <div className="time">{counterText}</div>
          ))}
        <div className="burger__logo">
          {/* Віктор<span className="red">іЯ</span> */}
          <img src={setWhiteLogo()} alt="Pdr Автошкола в смартфоні" />
        </div>
      </div>
      <aside className={`aside ${active ? "active" : ""}`}>
        <div className="aside__inner">
          <nav className="aside__menu menu">
            <ul className="menu__list">
              <li className="menu__item">
                <NavLink to="/" className="menu__logo">
                  <img src={setWhiteLogo()} alt="Pdr Автошкола в смартфоні" />
                </NavLink>
              </li>
              {typesBack.includes(history.location.pathname) && (
                <li className="menu__item" onClick={setBack}>
                  <button className="menu__link">Назад</button>
                </li>
              )}

              {token ? (
                <>
                  {history.location.pathname === "/started_learn" && (
                    <li className="menu__item menu__item--second">
                      <NavLink to="/my_test" className="menu__link">
                        Прогрес навчання
                      </NavLink>
                    </li>
                  )}
                  {history.location.pathname === "/test" && (
                    <li className="menu__item" onClick={resetTest}>
                      <button
                        className="menu__btn btn btn--red btn--sm btn--rounded-md"
                        onClick={setPage}
                      >
                        Завершити тест
                      </button>
                    </li>
                  )}
                  {history.location.pathname === "/my_test" && (
                    <li className="menu__item">
                      <NavLink to="/learn" className="menu__link">
                        Пройти тести
                      </NavLink>
                    </li>
                  )}
                  {!types.includes(history.location.pathname) && (
                    <>
                      {user && !user?.premium_check && (
                        <li className="menu__item">
                          <button
                            className="menu__btn btn btn--yellow btn--sm btn--rounded-md"
                            onClick={openModalPay}
                          >
                            ПРЕМІУМ підписка
                          </button>
                        </li>
                      )}
                      <li className="menu__item" onClick={logoutUser}>
                        <button className="menu__btn btn btn--red btn--sm btn--rounded-md">
                          Вийти
                        </button>
                      </li>
                    </>
                  )}
                </>
              ) : null}
            </ul>
          </nav>
          <div className="aside__pay">
            <img
              src={`${images["mastercard.svg"]}`}
              alt="mastercard"
              width="1"
              height="1"
            />
            <img
              src={`${images["visa.svg"]}`}
              alt="visa"
              width="1"
              height="1"
            />
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
