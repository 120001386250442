import { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Select from "react-select";
import ReactInputMask from "react-input-mask";
import makeAnimated from "react-select/animated";
import { setLogo } from "../../helpers/logo";

import Title from "../../common/Title/Title";
import Header from "../../hoc/header";
import Footer from "../../hoc/footer";
import Button from "../../common/Button/Button";
import Field from "../../common/Fields/Field/Field";
import Error from "../../common/Error/Error";

import "./premium.scss";

export default function Premium({
  token,
  errors,
  errorSystem,
  link,
  price,
  user,
  categories,
  onRegister,
  setPrice,
  setCategories,
  setErrors,
  setErrorsSystem,
}) {
  const history = useHistory();

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    categories: ["2"],
    group_code: "",
    buy_premium: true,
    accept: true,
  });
  const [qrCodeValue, setQrCodeValue] = useState(null);

  useEffect(() => {
    if (user?.group_code) {
      setUserData({ ...userData, group_code: user.group_code });
      setQrCodeValue(user.group_code);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  useEffect(() => {
    setPrice();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCategories();
    // eslint-disable-next-line
  }, []);

  const handleregisterPremium = (e) => {
    e.preventDefault();
    const data = {
      first_name: user?.first_name ? user?.first_name : userData.first_name,
      last_name: user?.last_name ? user?.last_name : userData.last_name,
      categories: userData.categories,
      group_code: user?.group_code ? user?.group_code : userData.group_code,
      buy_premium: userData.buy_premium,
      accept: userData.accept,
    };
    setErrors({});
    setErrorsSystem("");
    onRegister({ data, token });
  };

  function isCategoryB(category) {
    return category.label === "B";
  }

  const setBack = () => {
    history.goBack();
    setErrors({});
    setErrorsSystem("");
  };

  const animatedComponents = makeAnimated();

  return (
    <div className="main">
      <div className="main__header">
        <Header />
      </div>
      <NavLink to="/started_learn" className="main__logo">
        <img
          src={setLogo()}
          alt="Pdr Автошкола в смартфоні"
          width="1"
          height="1"
        />
      </NavLink>

      <div className="premium">
        <div className="container premium__container">
          <form className="premium__form" onSubmit={handleregisterPremium}>
            <Title
              title="Дозаповніть поля для оформлення Преміум доступу"
              titlesize="sm"
              titleClasses="premium__title"
              titleTag="h1"
              subtitle="Вартість ПРЕМІУМ доступу:"
              subtitleColor="green"
              position="center"
              price={price}
            />

            {!user?.last_name && (
              <div>
                <Field
                  labelText="Прізвище"
                  id="last_name"
                  name="last_name"
                  type="text"
                  forLabel="last_name"
                  inputSize="sm"
                  inputColor="white"
                  onChange={(e) =>
                    setUserData((prevUser) => ({
                      ...prevUser,
                      last_name: e.target.value.trim(),
                    }))
                  }
                />
                {errors?.last_name && <Error errorText={errors.last_name} />}
              </div>
            )}

            {!user?.first_name && (
              <div>
                <Field
                  labelText="Ім’я"
                  id="first_name"
                  name="first_name"
                  type="text"
                  forLabel="first_name"
                  inputSize="sm"
                  inputColor="white"
                  onChange={(e) =>
                    setUserData((prevUser) => ({
                      ...prevUser,
                      first_name: e.target.value.trim(),
                    }))
                  }
                />
                {errors?.first_name && <Error errorText={errors.first_name} />}
              </div>
            )}

            {categories && (
              <div>
                <div className="field">
                  <label className="field__title" htmlFor="categories">
                    Категорія
                  </label>

                  <Select
                    className="field__select select"
                    name="categories"
                    id="categories"
                    onChange={(val) =>
                      setUserData((prev) => ({
                        ...prev,
                        categories: val.map((v) => v.id),
                      }))
                    }
                    options={categories.data}
                    isMulti
                    defaultValue={categories.data.find(isCategoryB)}
                    classNamePrefix="select"
                    components={animatedComponents}
                    placeholder="Оберіть категорію прав:"
                  />
                </div>
                {errors?.categories && <Error errorText={errors.categories} />}
              </div>
            )}

            <div>
              <ReactInputMask
                mask="999 99 999"
                maskChar="_"
                value={qrCodeValue}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    group_code: e.target.value.trim(),
                  }))
                }
              >
                {(inputProps) => {
                  return (
                    <Field
                      labelText="Код групи (якщо він Вам відомий)"
                      id="group_code"
                      name="group_code"
                      type="text"
                      forLabel="group_code"
                      inputSize="sm"
                      inputColor="white"
                      isDisabled={user.group_code ? true : false}
                      {...inputProps}
                    />
                  );
                }}
              </ReactInputMask>
              {errors?.group_code && <Error errorText={errors.group_code} />}
            </div>

            {errorSystem && <Error errorText={errorSystem} />}

            <div className="premium__btns">
              <Button btnText="Оплатити" bg="yellow" type="submit" />
              <Button btnText="Назад" bg="black" onClick={setBack} />
            </div>
          </form>
        </div>
      </div>

      <div className="main__footer">
        <Footer />
      </div>
    </div>
  );
}
