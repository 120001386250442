import { useEffect, useState } from "react";

import Footer from "../../hoc/footer";
import Header from "../../hoc/header";

import Preference from "./Sections/Preference/Preference";
import AboutUs from "./Sections/AboutAs/AboutUs";
import Advantages from "./Sections/Advantages/Advantages";
import Banner from "./Sections/Banner/Banner";
import Tariffs from "./Sections/Tariffs/Tariffs";
import TryIt from "./Sections/TryIt/TryIt";
import Feedback from "./Sections/Feedback/Feedback";
import ProgressSection from "./Sections/ProgressSection/ProgressSection";

function Main({
  token,
  user,
  onGetFreePremium,
  premiumContent,
  errorsPremium,
  feedback,
  errorsFeedback,
  onGetFeedback,
  onSetFeedbackSucces,
  onSetFeedbackErrors,
  onGetFreePremiumSubscribe,
  freePremium,
  onSetFreePremiumSubscribe,
  onSetFreePremiumMessage,
  freePremiumMessage,
  onGetUser,
  price,
  setConfig,
  usersAmounts,
}) {
  const [code, setCode] = useState(null);

  useEffect(() => {
    setConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);

    let codeParam = url.searchParams.get("code");

    if (!codeParam) {
      const hashParams = new URLSearchParams(url.hash.split("?")[1]);
      codeParam = hashParams.get("code");
    }

    setCode(codeParam);
    // eslint-disable-next-line
  }, []);

  if (code) {
    const url = new URL(window.location.href);
    let newCodeParam;

    if (code.includes("-")) {
      newCodeParam = code.replace(/-/g, " ");
    } else {
      const percentEncodedString = JSON.stringify(code)
        .replace(/\\u00/g, " ")
        .replace(/"/g, "");
      newCodeParam = decodeURIComponent(percentEncodedString);
    }

    sessionStorage.setItem("codeParam", JSON.stringify(newCodeParam));
    url.searchParams.delete("code");
    if (url.hash.includes("?code=")) {
      const newHash = url.hash.replace(/\??code=[^&]+/, "").replace(/\?$/, "");
      url.hash = newHash;
    }

    window.history.replaceState({}, document.title, url.toString());
  }

  return (
    <div className="main">
      <Header />

      <main>
        <Banner token={token} />

        {token && user.premium_check ? (
          <ProgressSection />
        ) : (
          <>
            <AboutUs usersAmounts={usersAmounts} />
            <Tariffs token={token} user={user} price={price} />
          </>
        )}
        <Advantages token={token} user={user} />
        <Preference />
        <TryIt
          token={token}
          onGetFreePremium={onGetFreePremium}
          premiumContent={premiumContent}
          errorsPremium={errorsPremium}
          onGetFreePremiumSubscribe={onGetFreePremiumSubscribe}
          freePremium={freePremium}
          onSetFreePremiumSubscribe={onSetFreePremiumSubscribe}
          freePremiumMessage={freePremiumMessage}
          onSetFreePremiumMessage={onSetFreePremiumMessage}
          onGetUser={onGetUser}
        />
        <Feedback
          feedback={feedback}
          errorsFeedback={errorsFeedback}
          onGetFeedback={onGetFeedback}
          onSetFeedbackSucces={onSetFeedbackSucces}
          onSetFeedbackErrors={onSetFeedbackErrors}
        />
      </main>

      <div className="main__footer">
        <Footer />
      </div>
    </div>
  );
}

export default Main;
