import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = () => {
  const hostname = window.location.hostname;

  const imageUrl =
    hostname === "pdr.ua"
      ? "https://pdr.ua/pdrua.jpg"
      : "https://pdr.in.ua/pdrinua.jpg";

  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta
        name="description"
        content="Онлайн тренажер для вивчення та тестування знань з правил дорожнього руху"
      />
      <meta property="og:title" content="Автошкола в смартфоні" />
      <meta property="og:site_name" content={hostname} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:description" content="Автошкола в смартфоні" />
      <meta property="og:image" content={imageUrl} />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default MetaTags;
